html, body, #root {
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.ui.modal > div.header {
  background-color: #333333;
  color: #FFF;
  border-radius: 0 !important;
}
.ui.modal > div.content {
  border-radius: 0 !important;
}

table.scroll tbody {
  display: block;
  height: 202px;
  overflow: auto;
}

table.scroll thead,
table.scroll tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.sidescroll {
  overflow-x: scroll;
}

body.swal2-height-auto{
  height: 100% !important;
}